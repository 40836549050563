import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  PwnAgreements,
  StrapiAuthenticationConfiguration,
  StrapiImageClass,
  StrapiNewsletterComponent,
  StrapiPage,
} from '@moose/pwn-cms-model/lib';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { Constants } from '@shared/util/constants';
import { Subscription } from 'rxjs';
import { NewsletterService } from '@app/@shared/service/newsletter/newsletter.service';
import { AgreementsService } from '@app/@shared/service/agreements/agreements.service';
import { AgreementControl } from '@app/@shared/model/agreements/agreement-control';
import { ConfigurationService } from '@app/@shared/service/configuration/configuration.service';
import { DOCUMENT, Location } from '@angular/common';
import { ToastService } from '@shared/service/toast/toast.service';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';
import { SyneriseService } from '@app/@shared/service/synerise/synerise.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  component: StrapiNewsletterComponent;

  @Input()
  style: string;

  title = '';
  description = '';
  emailForm: UntypedFormGroup;
  email: UntypedFormControl;

  agreementsForm: UntypedFormGroup;
  mandatoryAgreements: AgreementControl[];
  optionalAgreements: AgreementControl[];

  authConfig: StrapiAuthenticationConfiguration;
  agreements: PwnAgreements;

  @Input()
  pages: StrapiPage[];

  newsletterImage: StrapiImageClass;

  subscription: Subscription;
  authConfigSubscription: Subscription;
  agreementsSubscription: Subscription;
  configSubscription: Subscription;

  subscribingToNewsletter = false;
  singleOptIn = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbModal,
    private newsletterService: NewsletterService,
    private agreementsService: AgreementsService,
    private location: Location,
    private configurationService: ConfigurationService,
    private toastService: ToastService,
    private syneriseService: SyneriseService
  ) {}

  ngOnInit(): void {
    this.configSubscription = this.configurationService
      .getNewsletter()
      .subscribe((newsletterConfig) => {
        if (newsletterConfig) {
          this.newsletterImage = new StrapiImageClass(newsletterConfig.image);
          if (this.style === 'small') {
            this.title = newsletterConfig.titleSmall;
            this.description = newsletterConfig.descriptionSmall;
          } else {
            this.title = newsletterConfig.title;
            this.description = newsletterConfig.description;
          }
        }
      });

    this.createEmailFormControls();
    this.createEmailForm();

    this.configurationService
      .getAuthenticationConfiguration()
      .subscribe((authConfig) => {
        if (!authConfig) {
          return;
        }

        this.agreementsSubscription = this.agreementsService
          .getAgreements()
          .subscribe((agreements) => {
            if (!agreements) {
              return;
            }

            if (authConfig.additionalAgreementTextNewsletter) {
              authConfig.additionalAgreementTextNewsletter = this.changelinkToBlank(
                authConfig.additionalAgreementTextNewsletter
              );
            }

            this.authConfig = authConfig;
            this.agreements = agreements;

            this.createAgreementsFormControls();
            this.createAgreementsForm();
          });
      });
  }

  ngAfterViewInit(): void {
    this.syneriseService.initFormCatch();
  }

  changelinkToBlank(html: string) {
    const indexOfAnchor = html.match(/<a ([^<]+)>/).index;
    return (
      html.substring(0, indexOfAnchor + 3) +
      'target="blank" ' +
      html.substring(indexOfAnchor + 3)
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.authConfigSubscription?.unsubscribe();
    this.agreementsSubscription?.unsubscribe();
    this.configSubscription?.unsubscribe();
  }

  createEmailForm() {
    this.emailForm = this.formBuilder.group({
      email: this.email,
    });
  }

  createAgreementsForm() {
    this.agreementsForm = this.formBuilder.group({});

    for (const control of [
      ...this.mandatoryAgreements,
      ...this.optionalAgreements,
    ]) {
      this.agreementsForm.addControl(
        'agreement-' + control.code,
        control.control
      );
    }
  }

  createEmailFormControls() {
    this.email = new UntypedFormControl('', [
      Validators.pattern(Constants.EMAIL_PATTERN),
      Validators.required,
      Validators.maxLength(254),
    ]);
  }

  createAgreementsFormControls() {
    this.mandatoryAgreements = [];
    this.optionalAgreements = [];

    for (const agreement of this.authConfig.mandatoryNewsletterAgreements) {
      const ssoAgreement = this.agreements.agreements.find(
        (agr) => agr.id === agreement.code
      );

      this.mandatoryAgreements.push({
        control: new UntypedFormControl('', Validators.required),
        code: agreement.code,
        version: ssoAgreement.version,
        text: ssoAgreement.shortDescription,
        fullText: ssoAgreement.fullDescription,
      });
    }

    for (const agreement of this.authConfig.optionalNewsletterAgreements) {
      const ssoAgreement = this.agreements.agreements.find(
        (agr) => agr.id === agreement.code
      );

      this.optionalAgreements.push({
        control: new UntypedFormControl(''),
        code: agreement.code,
        version: ssoAgreement.version,
        text: ssoAgreement.shortDescription,
        fullText: ssoAgreement.fullDescription,
      });
    }
  }

  openModal(content: any): void {
    this.modal
      .open(content, {
        backdrop: 'static',
        windowClass: 'newsletter-modal-window',
        backdropClass: 'newsletter-modal-backdrop',
      })
      .result.then(
        (fulfilled) => this.removeToasts(),
        (reject) => this.removeToasts()
      );
  }

  changeModalPage(activeModal: NgbActiveModal, content: any): void {
    if (this.agreementsForm.valid && !this.subscribingToNewsletter) {
      this.subscribingToNewsletter = true;
      if (this.document) {
        const redirectUrl = `${this.document.location.href}?fromnl=1`;

        this.subscription?.unsubscribe();
        this.subscription = this.newsletterService
          .subscribeToNewsletter(
            this.emailForm.value,
            this.getSelectedAgreements(),
            redirectUrl
          )
          .subscribe((response) => {
            this.subscribingToNewsletter = false;
            this.singleOptIn = response.single_opt_in;
            activeModal.close();
            this.openModal(content);
          });
      }
    }
  }

  protectedEmail() {
    return (
      this.email.value.split('@')[0].substring(0, 2) +
      this.email.value
        .split('@')[0]
        .substring(2, this.email.value.indexOf('@'))
        .replace(/[A-Za-z0-9+_.]/g, '*') +
      '@' +
      this.email.value.split('@')[1]
    );
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  hasErrors(control: UntypedFormControl) {
    return control && control.errors && (control.dirty || control.touched);
  }

  areMandatoryAgreementsAccepted(): boolean {
    return true; // this.mandatoryAgreements.findIndex((agr) => agr.control.value) === -1;
  }

  displayToast(id: string, text: string): void {
    this.toastService.replaceToast(
      text,
      { classname: 'newsletter-toast', delay: null },
      id
    );
  }

  removeToasts(): void {
    this.toastService.removeAllToasts();
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }

  private getSelectedAgreements(): PwnAgreements {
    return {
      agreements: this.getSelectedAgreementControls().map((agreement) => ({
        id: agreement.code,
        version: agreement.version,
        url: window.location.origin + '/zgoda/' + agreement.code,
      })),
    };
  }

  private getSelectedAgreementControls(): AgreementControl[] {
    return [
      ...this.mandatoryAgreements.filter((agr) => agr.control.value),
      ...this.optionalAgreements.filter((agr) => agr.control.value),
    ];
  }
}
