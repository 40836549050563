import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  DomSanitizer,
  makeStateKey,
  Meta,
  SafeHtml,
  Title,
  TransferState,
} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';

import { environment } from '@env/environment';
import { Logger } from '@core';
import { LinksService } from '@shared/service/links/links.service';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { StrapiConfiguration, StrapiTheme } from '@moose/pwn-cms-model/lib';
import { PlatformService } from '@shared/service/ssr/platform.service';
import { WindowService } from '@shared/service/ssr/window.service';
import { SassHelperComponent } from '@shared/providers/sass-helper/sass-helper.component';
import { RouteLoggerService } from '@shared/service/tool/route-logger.service';
import { MetaDataService } from '@shared/service/meta-data-service/meta-data.service';
import { SyneriseService } from './@shared/service/synerise/synerise.service';
import { PrimeNGConfig } from 'primeng/api';
import { UtmInterceptorService } from './@core/http/utm-interceptor.service';
import { GetResponseService } from './@shared/service/get-response/get-response.service';
import { catchError, concatMap, take } from 'rxjs/operators';

const log = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './app.component.mobile.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  links: string | undefined = null;
  showLinks = false;
  showCookiesPopup = true;
  private isSessionStarted = false;
  cookiePolicyContent: SafeHtml;

  @ViewChild(SassHelperComponent, { static: true })
  private sassHelper: SassHelperComponent;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  CONFIG_STATE_KEY = makeStateKey<SafeHtml>('config');

  private routerSubscription: Subscription;
  private linksSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private linksService: LinksService,
    private configurationService: ConfigurationService,
    private cookieService: CookieService,
    private domSanitizer: DomSanitizer,
    private utmInterceptor: UtmInterceptorService,
    private platformService: PlatformService,
    private windowService: WindowService,
    private routeLoggerService: RouteLoggerService,
    private state: TransferState,
    private _getresponseService: GetResponseService,
    private metaDataService: MetaDataService,
    private syneriseService: SyneriseService,
    private primeConfig: PrimeNGConfig
  ) {
    this.routerSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showLinks = false;
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.syneriseService.pageVisit();
        if (this.cookieService.get('gr-contactId')) {
          let payload: any = {
            bod: {
              referal_domainText: document.referrer || 'direct',
              date: new Date().toISOString(),
              url: window.location.href,
            },
            contactId: this.cookieService.get('gr-contactId'),
          };
          const utmParams = [
            'utm_campaign',
            'utm_source',
            'utm_medium',
            'utm_term',
            'utm_content',
          ];

          const urlParams = new URLSearchParams(window.location.search);

          utmParams.forEach((param) => {
            const value = urlParams.get(param);
            if (value) {
              payload[param] = value;
            }
          });
          if (utmParams.length > 0) {
            this._getresponseService
              .pageVisitUtm(payload)
              .pipe(
                catchError(() => {
                  return of(null);
                })
              )
              .subscribe();
          }
          this._getresponseService
            .pageVisit(payload)
            .pipe(
              catchError(() => {
                return of(null);
              })
            )
            .subscribe();
        }
      }
    });
    this.router.events.pipe(take(1)).subscribe((event) => {
      const afterRegisterData = this.cookieService.get('gr-hub-after-register');
      if (afterRegisterData) {
        const afterRegisterDataJson = JSON.parse(
          this.cookieService.get('gr-hub-after-register')
        );
        const payloadContact = {
          email: afterRegisterDataJson.email,
        };
        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date
          .getDate()
          .toString()
          .padStart(2, '0')} ${date
          .getHours()
          .toString()
          .padStart(2, '0')}:${date
          .getMinutes()
          .toString()
          .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        this._getresponseService
          .getContact(payloadContact)
          .subscribe((item) => {
            if (item[0]?.contactId) {
              this.cookieService.set('gr-contactId', item[0]?.contactId);
              const contactId = item[0]?.contactId;
              const payloadStart = {
                bod: {
                  date: new Date().toISOString(),
                },
                contactId: contactId,
              };
              const updatedPayloadStart = {
                ...payloadStart,
                contactId: contactId,
              };
              const payload: any = {
                occupation_pwn: afterRegisterDataJson.occupation_group,
                specialization: afterRegisterDataJson.specialization,
                firstname: afterRegisterDataJson.firstname,
                lastname: afterRegisterDataJson.lastname,
                contactId: contactId,
              };
              this._getresponseService
                .updateContact(payload)
                .pipe(
                  concatMap(() => {
                    return this._getresponseService.startSession(
                      updatedPayloadStart
                    );
                  })
                )
                .subscribe(() => {
                  const formattedDate = new Date().toISOString();
                  this.cookieService.set('gr-start-session', formattedDate);
                  this.cookieService.delete('gr-hub-after-register');
                });
            }
          });
      }
    });
  }

  ngOnInit() {
    this.loadStyles();
    if (!PlatformService.isBrowser) {
      this.showCookiesPopup = false;
    }
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    log.debug('App component started...');
    this.authService.refreshToken().subscribe();

    this.cookiePolicyContent = this.state.get<SafeHtml>(
      this.CONFIG_STATE_KEY,
      null as SafeHtml
    ) as SafeHtml;

    if (!this.cookiePolicyContent) {
      this.configurationService
        .getConfiguration()
        .subscribe((config: StrapiConfiguration) => {
          if (config) {
            this.cookiePolicyContent = this.domSanitizer.bypassSecurityTrustHtml(
              config.cookiesPolicy
            );
          }
        });
    }

    if (PlatformService.isBrowser) {
      log.debug('Browser platform started...');

      // this.metaDataService.setData('this.defaultConfigTitle', 'this.defaultConfigDesc', 'this.defaultConfigLogoUrl', 'wp.pl', MetaOgType.Website);
      // this.metaDataService.setDefaultMetaData();

      const cookie = this.cookieService.get('nursing-accept-cookies');
      if (cookie) {
        this.showCookiesPopup = false;
      }

      this.linksSubscription = this.linksService
        .getLinks()
        .subscribe((links) => {
          if (links && links.length > 0) {
            this.links = links;
            this.showLinks = true;
          }
        });
      if (document) {
        let node = document.createElement('script');
        node.textContent =
          '(function (w, d, s, l, i) {' +
          'w[l] = w[l] || [];' +
          'w[l].push({' +
          '"gtm.start": new Date().getTime(),' +
          'event: "gtm.js",' +
          '});' +
          'var f = d.getElementsByTagName(s)[0],' +
          'j = d.createElement(s),' +
          'dl = l != "dataLayer" ? "&l=" + l : "";' +
          'j.async = true;' +
          'j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;' +
          'f.parentNode.insertBefore(j, f);' +
          '})(window, document, "script", "dataLayer", "GTM-WKKQWPF");';
        document.head.appendChild(node);

        this.router.events.subscribe((event) => {
          if (!(event instanceof NavigationEnd)) {
            return;
          }
          this.gtagConfigPagePath(event.urlAfterRedirects, 'GTM-WKKQWPF');
          this.windowService.scrollTo(0, 0);
        });

        // node = document.createElement('script');
        // node.src = 'https://www.googletagmanager.com/gtag/js?id=UA-3856065-2';
        // node.type = 'text/javascript';
        // node.async = true;
        // node.defer = true;
        // document.head.appendChild(node);

        // node = document.createElement('script');
        // node.textContent =
        //   'window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);}gtag("js", new Date()); gtag("config", "UA-3856065-2", { anonymize_ip: true });';
        // document.head.appendChild(node);

        node = document.createElement('script');
        node.src = 'https://cdn.embedly.com/widgets/platform.js';
        node.type = 'text/javascript';
        node.async = true;
        node.defer = true;
        document.head.appendChild(node);

        node = document.createElement('script');
        node.textContent = `function onSyneriseLoad() {
          SR.init({
              trackerKey: '080e6e95-0d0e-47af-af14-139c93801264',
              'trackingDomain': 'https://apima.psychologia.pwn.pl',
              'customPageVisit': true,
              'dynamicContent': {
                  'virtualPage': true
              }
          });
          SR.event.pageVisit().then(function() {
              SR.dynamicContent.get();
          });
      }(function(s, y, n, e, r, i, se) {
          s['SyneriseObjectNamespace'] = r;
          (s[r] = s[r] || []), (s[r]._t = 1 * new Date()), (s[r]._i = 0), (s[r]._l = i);
          var z = y.createElement(n),
              se = y.getElementsByTagName(n)[0];
          z.async = 1;
          z.src = e;
          se.parentNode.insertBefore(z, se);
          z.onload = z.onreadystatechange = function() {
              var rdy = z.readyState;
              if (!rdy || /complete|loaded/.test(z.readyState)) {
                  s[i]();
                  z.onload = null;
                  z.onreadystatechange = null;
              }
          };
      })(window, document, 'script', '//apima.psychologia.pwn.pl/i10ecsb90.js', 'SR', 'onSyneriseLoad');`;
        document.head.appendChild(node);
      }
      // node = document.createElement('script');
      // node.src = 'https://script.crazyegg.com/pages/scripts/0088/6752.js';
      // node.type = 'text/javascript';
      // node.async = true;
      // node.defer = true;
      // document.head.appendChild(node);
    } else {
      log.debug('Server platform started...');
    }

    this.setPrimeNgConfig();
  }

  acceptCookies() {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    this.cookieService.set('nursing-accept-cookies', 'true', date);
    this.showCookiesPopup = false;
  }

  loadStyles(): void {
    this.configurationService.getTheme().subscribe((res: StrapiTheme) => {
      if (res && res.style) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { __id, id, createdAt, updatedAt, __v, ...rest } = res.style;
        const colorMap = new Map<string, string>(Object.entries(rest));
        colorMap.delete('_id');
        colorMap.delete('updated_by');
        colorMap.delete('created_by');

        colorMap.forEach((value, key) => {
          this.sassHelper.setProperty(key, value);
        });

        if (colorMap.size > 0) {
          this.sassHelper.setModifiedProperties(colorMap);
        }
      }
    });
  }

  setPrimeNgConfig(): void {
    this.primeConfig.setTranslation({
      firstDayOfWeek: 0,
      dayNames: [
        'Niedziela',
        'Poniedziałek',
        'Wtorek',
        'Środa',
        'Czwartek',
        'Piątek',
        'Sobota',
      ],
      dayNamesShort: ['NIEDZ.', 'PON.', 'WT.', 'ŚR.', 'CZW.', 'PT.', 'SOB.'],
      dayNamesMin: ['NIEDZ.', 'PON.', 'WT.', 'ŚR.', 'CZW.', 'PT.', 'SOB.'],
      monthNames: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
      ],
      monthNamesShort: [
        'Sty',
        'Lut',
        'Mar',
        'Kwi',
        'Maj',
        'Cze',
        'Lip',
        'Sie',
        'Wrz',
        'Paź',
        'Lis',
        'Grud',
      ],
      today: 'Dzisiaj',
      clear: 'Wczyść',
      dateFormat: 'yy-mm-dd',
      weekHeader: 'Tydz',
    });
  }

  gtagConfigPagePath(url: string, gID: string): void {
    if (!PlatformService.isBrowser) {
      return;
    }
    const win = window as any;
    if (!win.gtag) {
      return;
    }
    win.gtag('config', gID, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      page_path: url,
    });
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
    this.linksSubscription?.unsubscribe();
  }
}
